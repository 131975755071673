/**
 * 路径拼接
 *
 * @param 根路径
 * @param 子路径
 */
export const resolve = (root, path) => {
  if (!root) {
    root = "";
  }
  if (!path) {
    path = "";
  }
  if (root.endsWith("/")) {
    if (path.startsWith("/")) {
      return root + path.trimStart("/");
    } else {
      return root + path;
    }
  } else {
    if (path.startsWith("/")) {
      return root + path;
    } else {
      return root + "/" + path;
    }
  }
};

/**
 * 模块化导入
 *
 * @param context
 */
export const seModule = (context) => {
  return Object.keys(context).reduce((modules, key) => {
    return {
      ...modules,
      ...context[key].default,
    };
  }, {});
};
