export default {
  public: {
    product_service: '产品服务',
    se_overseas_warehouse: '赛易海外仓',
    se_finance: '赛易金融',
    law: '法律',
    privacy_policy: '隐私政策',
    terms_of_use: '使用条款',
    se_logistics: "赛易物流",
    developer_documentation: '开发者文档',
    API_technical_documentation: 'API技术文档',
    cooperation: '合作',
    partner_program: '合作伙伴计划',
    business_cooperation: '商务合作',
    about_us: '关于我们',
    privacy:"隐私政策",
    home_page: '首页',
    our_services: '我们的服务',
    learn_more: '了解更多',
    more: '更多',
    API_interface: 'API接口',
    core_advantages: '核心优势',
    visual_operation: '可视操作',
    easy_management: '轻松管理',
    safe_and_reliable: '安全可靠',
    quick_response: '快速响应',
    our_motivation: '我们的动力',
    our_values: '我们的价值观',
    mission: '使命',
    vision: '愿景',
    target: '目标',
    service_text_01: '国际商业快递，空派，海派当地卡车，空运、陆运、快递一应俱全，赢得先机',
    service_text_02: '双仓模式，兼容全尺寸和重量可按箱按件灵活管理海外库存优化销售成本',
    service_text_03: '信用支付、存货质押、垫资代采等多种供应链金融产品赋能全球增长',
    api_interface_text: '丰富的API接口，可基于您现有的企业管理系统轻松链接我们',
    about_banner_title: '让跨境销售更简单',
    about_banner_text_01: '世界在日新月异，销售的变化来的更快更猛烈。',
    about_banner_text_02: '全球销售渠道变得越来越碎片化，多样化，不变的是货物的真实流动与交付。Saleasy赛易致力于建立低成本、快捷并易于使用的基础系统，成为全球跨境销售的重要基础设施',
    about_banner_text_03: '从船东到航司，底层的运力整合，可控调度，价格时效，损失赔付由赛易物流负责，安全无忧',
    about_banner_text_04: '整合了当地的运输资源，结合赛易海外仓，让你像在国内一样便捷使用当地物流',
    motivation_text_01: '以技术为核心，构建跨境销售基础设施与系统应用，让跨境销售更简单',
    motivation_text_02: '赋能跨境企业无界发展的基础设施帮助客户全球展业',
    motivation_text_03: '连接制造商，零售商与消费者，在全球机遇中实现共赢',
    modular_card_title_01: '按箱存储，一箱一码，精细管理到每一箱',
    modular_card_title_02: '一件代发，本地配送，全重量段支持多种优质配送方案选择',
    modular_card_text_01: '适用于Amazon,MercadoLibre等电商平台供货，以及B2B销售的订单的库存及配送',
    modular_card_text_02: '适用于Shopify, 独立网站，Tiktok直播等零售订单的库存及配送',
    advantages_text_01: '货物尺寸，重量数据透明，可实时查看入库及异常货物的图像',
    advantages_text_02: '操作界面简单友好，轻松控制所有仓库库存，流程状态实时更新',
    advantages_text_03: '条码化管理，全仓扫描，全轨迹跟踪，确保库存万无一失',
    advantages_text_04: '36小时内完成库内指令，让你的订单领先一步',
    advantages_text_05: '安全保障，丢货必赔',
    advantages_text_06: '时效精准，延误赔付',
    advantages_text_07: '全程可视化跟踪',
    advantages_text_08: '轻松下单, 价格每日更新',
    core_advantages_title_01: '全程保价',
    core_advantages_title_02: '时效保证',
    core_advantages_title_03: '实时追踪',
    core_advantages_title_04: '一键下单',
    se_warehouse_type_1: "存储仓",
    se_warehouse_type_2: "配送仓",
    card_subtitle_1: "快递",
    card_subtitle_2: "空派",
    card_subtitle_3: "海派",
    card_subtitle_4: "海卡",
    card_subtitle_5: "整柜",
    card_subtitle_6: "包机",
    card_subtitle_7: "物流",
    card_subtitle_8: "卡车",
    card_title_1: "国际运输",
    card_title_2: "本土运输",
    register: "注册",
    internal_test: "内测中",
    about_saleasy: "关于赛易",
    about_saleasy_text: "赛易(深圳)供应链科技有限公司是一家以技术为核心，以行业强大底层资源为输出的创新平台型电商服务企业。核心团队成员多数来自UPS、马士基、Paypal等行业头部企业，拥有超过10年甚至20年的跨境物流及电商行业经验。赛易致力于打造行业标杆型电商基础服务体系，为广大从事跨境电商销售的企业提供优质的国际物流、海外仓储及配送的一站式解决方案，让跨境销售更简单。",
    email: "电子邮箱",
    contact_address: "联系地址",
    open_platform: "开放平台",
    business_consulting_please_contact: "业务咨询请联系",
  }
}