//用户信息相关:中文
export default {
    user:{
        /*角色相关 begin*/
        role_name:"角色名称",
        description:"描述",
        create_role:"创建角色",
        /*角色相关 end*/

        assign_role_permissions: "分配角色权限",
        address_name: "地址名称",
        create_address: "创建地址",
    }
};
  