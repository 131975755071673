export default {
  account: {
    essential_information: "基本信息",
    corporate_name: "公司名称",
    company_id: "公司编号",
    company_characteristic: "公司标识",
    registration_number: "统一信用代码",
    mobile_phone: "手机",
    secure_mailbox: "安全邮箱",
    account_security: "账户安全",
    change_password: "修改密码",
    original_password: "原密码",
    new_password: "新密码",
    enter_the_new_password_again: "二次输入新密码",
    the_passwords_entered_twice_do_not_match: "两次输入的密码不匹配",
    password_modified_successfully: "密码修改成功",
    please_input: "请输入",

    forget_password: "忘记密码",
    enter_verification_code: "输入验证码",
    cell_phone_number: "手机号",
    login_account: "登录账户",
    register_new_users: "注册新用户",
    create_account:"创建账户",

    registration_country:"注册国家",
    phone_number:"电话号码",
    phont_number_is_in_the_wrong_format:"电话号码格式错误",
    currently_only_supports_mainland_china:"目前仅支持中国大陆地区",
    confirm_password:"确认密码",
    validation_code:"验证码",
    send_sms:"发送短信",
    sms_validation_code:"短信验证码",

    complete_the_info_of_enterprise:"完善企业资料",
    data_is_under_review:"资料审核中",
    data_has_been_reviewed:"资料审核通过",
    data_review_failed:"资料审核未通过",
    pending_tip:"审核时间通常为1-2个工作日",
    corporate_name_tip:"请填写与营业执照上一致的公司全称",
    business_license:"营业执照",
    account_manager:"账户负责人",
    start_use:"开始使用",
    modify:"修改",
    registration_number_format_error:"统一社会信用代码格式错误",

    mobile_phone_number_is_not_registered: "手机号码未注册",
    SMS_sent_successfully: "短信发送成功",
    SMS_sending_failed: "短信发送失败",
    set_new_password: "设置新密码",
    enter_the_password_again: "再次输入密码",
    the_two_passwords_are_inconsistent: "两次密码不一致",
    account_registration_successful:"账户注册成功！",

    timezone: "时区",
    update_timezone: "修改时区",
    choose_timezone: "选择时区",

    service_authority:"服务授权",
    through_authorization_the_application_will_gain_access_to_your_API:"通过授权,该应用将获得您的API权限",
    after_authorization_you_will_be_granted_the_following_permissions:"授权后，将获得以下权限授权",
    refuse:"拒绝",
    agree_authorize:"同意授权",
    refund: "退款",
    role:"角色",
    account_name:"账号",
    country:"所在国家",
    registration_date:"注册时间",
    last_login_time:"最后登录时间",
    account_status:"账户状态",
    details:"详情",
    serial_number:"序号",
    login_time:"登录时间",
    user_roles:"用户角色",
    main_account:"主账号",
    create_account:"创建账号",
    nick_name:"名称",
    remove_user_tip:"你确认要移除该账户?",
    remove_user:"移除账号",
    password:"密码",
    password_default_tip:"默认密码为手机号后6位,登录后请重置密码",
    mark_as_read: "标记为已读",
    all_read: "全部已读",
    business_license_tips: "注意：中国香港地区的企业请上传“公司註冊證書”",
  }
}